import React from "react";
import Layout from "../app/layout";
import { NextPageContext } from "next";
import { useTranslation } from "next-i18next";
import GradientCard from "../components/ui-kit/gradient-card";
import SlideInWrapper from "../components/ui-kit/slide-in-wrapper";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export default function Custom404() {
  const { t } = useTranslation("common");

  return (
    <Layout homepage>
      <SlideInWrapper>
        <div style={{ padding: "0px 20px" }}>
          <GradientCard colorA="#eb5757" colorB="#bb6bd9">
            <h1>404 - {t("page-not-found")}</h1>
          </GradientCard>
        </div>
      </SlideInWrapper>
    </Layout>
  );
}

export async function getStaticProps({ locale }: NextPageContext) {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, ["common"])),
    },
  };
}
